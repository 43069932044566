// Icons
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FaxIcon from "@mui/icons-material/Fax";
import RoomIcon from "@mui/icons-material/Room";

const footer = {
  content: [
    {
      heading: "Ihr Immobilienmakler. Eine sichere Entscheidung.",
      images: [
        {
          src: "/images/logo.webp",
          description: "Logo von IFTS Immobilien Schmidt e.K.",
          title: "Ihr Immobilienmakler in der Region",
          width: 265,
          height: 408,
          srcSet:
            "/images/logo-klein.webp 265w, /images/logo-mittel.webp 530w, /images/logo.webp 795w",
          sizes: "(max-width:480px) 265px",
        },
      ],
    },
    {
      heading: "Kontakt",
      data: [
        {
          icon: <CallIcon />,
          name: { text: "Telefon" },
          value: {
            text: "+49 (0)7661 909 15 32",
            href: "tel:004976619091532",
          },
        },
        {
          icon: <MailOutlineIcon />,
          name: { text: "E-Mail" },
          value: {
            text: "info@ifts-immobilien.de",
            href: "mailto:info@ifts-immobilien.de",
          },
        },
        {
          icon: <FaxIcon />,
          name: { text: "Fax" },
          value: {
            text: "+49 (0)7661 970 92 65",
          },
        },
        {
          icon: <RoomIcon />,
          name: { text: "Adresse" },
          value: {
            text: "Erlenhofstraße 10, D - 79256 Buchenbach",
          },
        },
      ],
    },
    {
      heading: "Rechtliche Informationen",
      data: [
        {
          name: { text: "Impressum", href: "/impressum" },
        },
        {
          name: {
            text: "Datenschutz",
            href: "https://itrk.legal/pAS.8V.kXb.html",
          },
        },
        {
          name: { text: "Information zur Echtheit von Kundenbewertungen" },
          value: {
            text: "Unser Unternehmen sammelt Kundenbewertungen über Bestandskunden und unabhängige Dienstleister, wie beispielsweise Shopvote. Dabei werden automatische und manuelle Maßnahmen eingesetzt, um Kundenbewertungen zu verifizieren.",
          },
        },
        {
          value: {
            text: "Hier erhalten Sie Informationen zur Echtheit unserer Kundenbewertungen.",
            href: "/infobewertungen",
          },
        },
      ],
    },
  ],
};

export default footer;
