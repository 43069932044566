import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddLocationIcon from "@mui/icons-material/AddLocation";

const header = {
  logo: ["IFTS", "Immobilien Schmidt e.K."],
  menu: [
    {
      href: "/",
      text: "Home",
    },
    {
      href: "/immobilienbewertung",
      text: "Immobilienbewertung",
    },
    {
      href: "/verkaufsprozess",
      text: "Verkauf",
    },
    {
      href: "/vermietung",
      text: "Vermietung",
    },
    {
      href: "/ueber-uns",
      text: "Über uns",
    },
    {
      href: "/leistungen",
      text: "Leistungen",
    },
    {
      href: "/immobilien/1",
      text: "Immobilien",
    },
    {
      href: "/referenzen",
      text: "Referenzen",
    },
    {
      href: "/tippgeber",
      text: "Tippgeber",
    },
    {
      href: "/kontakt",
      text: "Kontakt",
    },
  ],
  contact: [
    {
      icon: <CallIcon />,
      text: ["+49 (0)7661 909 15 32", "tel:004976619091532"],
    },
    {
      icon: <MailOutlineIcon />,
      text: ["info@ifts-immobilien.de", "mailto:info@ifts-immobilien.de"],
    },
    {
      icon: <AddLocationIcon />,
      text: ["Erlenhofstraße 10, D - 79256 Buchenbach"],
    },
  ],
};

export default header;
