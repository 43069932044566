import styled from "styled-components";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function MapImage({ content }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      {content.image && (
        <ImageWrapper>
          <Image
            key={content.image.description}
            src={content.image.src}
            alt={content.image.description}
            loading="lazy"
            srcSet={content.image.srcSet && content.image.srcSet}
            sizes={content.image.sizes && content.image.sizes}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </ImageWrapper>
      )}
      {content.text &&
        content.text.map((paragraph, index) => (
          <Text
            key={paragraph + index}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ))}
      {isOpen && (
        <Lightbox
          mainSrc={content.detailImage}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom={true}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  max-width: 1440px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 0 30px;
  margin: 60px auto;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
  }
`;
const ImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 480px) {
    margin: 0;
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 800px;
  height: auto;
  cursor: pointer;
`;

const Text = styled.p`
  color: #777;
`;
