import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function Textbox(props) {
  const { content } = props;

  return (
    <WidthWrapper>
      <Heading>{content.heading}</Heading>
      {content.text.map((paragraph) => (
        <Text key={paragraph}>{paragraph}</Text>
      ))}
    </WidthWrapper>
  );
}

const Heading = styled.h3`
  position: relative;
  color: #393a3c;
  margin: 30px;
  font-size: 20px;

  &::after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #d4d5d6;
    width: 100%;
    max-width: 1700px;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 480px) {
    margin: 17px 30px 17px 30px;
  }
`;

const Text = styled.p`
  margin: 0 30px;
  color: #777;
  hyphens: auto;
  text-align: justify;
`;

const WidthWrapper = styled.div`
  max-width: 1440px;
  margin: 60px auto;
`;

Textbox.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    text: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
