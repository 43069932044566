import React from "react";

//components
import Container from "../components/Container";
import Textbox from "../components/Textbox";
import MapImage from "../components/MapImage";
import Table from "../components/Table";
import Video from "../components/Video";
import TextWithButton from "../components/TextWithButton";
import StepDescription from "../components/StepDescription";
import ColumnsLayoutHorizontal from "../components/ColumnsLayoutHorizontal";

//data
import { sellProcess } from "../data/sellprocess";


export default function SellProcess() {
  return (
    <Container main>
      <TextWithButton
        content={sellProcess.hero}
        style={{ textAlign: "center" }}
      />
      <StepDescription content={sellProcess.stepDescription} />
      <TextWithButton
        content={sellProcess.whyIFTSforSelling}
        style={{ textAlign: "center " }}
      />
      <TextWithButton
        content={sellProcess.exposeForSelling}
        style={{ textAlign: "center " }}
      />
      <TextWithButton
        content={sellProcess.documentsForSelling}
        style={{ textAlign: "center " }}
      />
      <Video video={sellProcess.video} />
      <TextWithButton
        content={sellProcess.videoDescription}
        style={{ textAlign: "center " }}
      />
      <MapImage content={sellProcess.mapImage} />
      <Table content={sellProcess.mapInformation} />
      <ColumnsLayoutHorizontal content={sellProcess.reasonsToBuy} />
      <Textbox content={sellProcess.textbox} />
    </Container>
  );
}
