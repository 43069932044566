export const realEstateValuation = {
  hero: {
    heading:
      "3 Schritte zur qualifizierten Marktpreiseinschätzung Ihrer Immobilie",
    subheading: "Verkaufen Sie nicht unter Wert.",
    text: [
      "Wir erstellen die Marktpreiseinschätzung <b>für Sie kostenfrei</b> und <b>ohne Vertragsbindung.</b>",
      "Lernen Sie uns und unsere Arbeit unverbindlich kennen.",
      "<b>Diskretion und eine vertrauensvolle Zusammenarbeit</b> garantieren wir Ihnen.",
    ],
    image: [
      {
        src: "/images/sparschwein.webp",
        sizes: "(max-width:480px) 175px",
        description: "Sparschwein",
        title: "Immobilie erfolgreich verkaufen",
      },
    ],
  },
  columnsLayout: [
    {
      heading: "1. Schritt",
      subheading: "Marktpreiseinschätzung anfordern",
      image: {
        src: "/images/leistungen/ib1.webp",
        sizes: "(max-width:480px) 360px",
        description: "Unterlagen Immobilienverkauf",
        title: "Immobilienbewertung jetzt anfordern",
      },
      text: [
        "Sie haben ein paar Minuten Zeit? Wir bieten Ihnen die Chance, den aktuellen Marktpreis Ihrer Immobilie zu ermitteln.",
        "Bei der folgenden Immobilienanalyse können Sie die Daten Ihrer Immobilie mühelos hinterlegen. Besondere Kenntnisse zur Immobilie sind nicht notwendig.",
        "Mit diesen Daten können wir umgehend mit der Marktpreiseinschätzung Ihrer Immobilie starten.",
      ],
    },
    {
      heading: "2. Schritt",
      subheading: "Qualifizierte Immobilienprüfung vor Ort",
      image: {
        src: "/images/leistungen/ib2.webp",
        sizes: "(max-width:480px) 360px",
        description: "umfassende Immobilienbewertung",
        title: "Immobilienexperten aus der Region",
      },
      text: [
        "Sie erhalten bei uns keine automatisch erstellte Kurzbewertung mit weitgefasstem Preisrahmen, die nur auf wenigen Immobilienangaben basiert.",
        "Jede Immobilie ist anders und muß vor Ort besichtigt werden, um den richtigen Kaufpreis zu ermitteln.",
        "Wir werden mit Ihnen zeitnah einen Termin zur Besichtigung der Immobilie vereinbaren. Gerne bereits in den nächsten Tagen.",
      ],
    },
    {
      heading: "3. Schritt",
      subheading: "Exakte Kaufpreisempfehlung erhalten",
      image: {
        src: "/images/immoverkauf.webp",
        sizes: "(max-width:480px) 360px",
        description: "hohen Kaufpreis erzielen",
        title: "erfolgreich verkaufen",
      },
      text: [
        "Sie erhalten zeitnah und unverbindlich einen ausführlichen Immobilienreport inklusive exakter Kaufpreisempfehlung für Ihre Immobilie.",
        "Dies garantiert Ihnen Sicherheit. Verkaufen Sie nicht unter Wert.",
      ],
    },
  ],

  description: {
    heading: "Jetzt Marktpreiseinschätzung starten",
  },
  advisory: {
    heading: "Sie wünschen eine persönliche Beratung?",
    subheading:
      "Unsere Immobilienexperten beantworten Ihre Fragen jederzeit gerne. Rufen Sie uns an. Alternativ können Sie uns eine Email senden. Wir rufen Sie zeitnah zurück.",
    button: {
      content: ["Unverbindliche Beratung", "/kontakt"],
      direction: "center",
    },
  },
  mapImage: {
    image: {
      src: "/images/landkarte.webp",
      description: "Freiburg Schwarzwald Breisach Kaiserstuhl",
      title: "Ihr Immobilienmakler in der Region.",
    },
    detailImage: "/images/landkarte-gross.webp",
  },
  mapInformation: [
    {
      headline: "Wir unterstützen Sie in allen Städten & Gemeinden der Region",
      statement: [
        {
          type: "list",
          columns: 3,
          listType: "'✓'",
          items: [
            "Freiburg - Altstadt",
            "Freiburg - Herdern",
            "Freiburg - Littenweiler",
            "Freiburg - Betzenhausen",
            "Freiburg - Haslach",
            "Freiburg - Hochdorf",
            "Freiburg - Wiehre",
            "Freiburg - Vauban",
            "Emmendingen",
            "Denzlingen",
            "Herbolzheim",
            "Breisach am Rhein",
            "Neuenburg am Rhein",
            "Bad Krozingen",
            "Kirchzarten",
            "Feldberg Schwarzwald",
            "Titisee-Neustadt",
            "Waldkirch",
            "Endingen am Kaiserstuhl",
            "Elzach",
            "Furtwangen im Schwarzwald",
            "Staufen im Breisgau",
            "viele weitere Städte & Gemeinden",
          ],
        },
      ],
    },
    {
      headline:
        "Wir erstellen Marktpreiseinschätzungen für folgende Immobilien",
      statement: [
        {
          type: "list",
          columns: 2,
          items: [
            "Eigentumswohnung",
            "Einfamilienhaus",
            "Zweifamilienhaus",
            "Mehrfamilienhaus",
            "Doppelhaushälfte",
            "Reihenhaus",
            "sowie anderweitige Wohnimmobilien",
          ],
        },
      ],
    },
    {
      headline: "Wie erziele ich einen guten Kaufpreis für meine Immobilie?",
      statement: [
        {
          type: "paragraph",
          text: "Sie möchten zeitnah einen guten Kaufpreis für Ihre Immobilie erzielen? Entscheidend für den Verkaufserfolg ist eine zielorientierte Vermarktungsstrategie, die strukturiert umgesetzt wird. Fehler bei der Vermarktung mindern den erzielbaren Kaufpreis erheblich.",
        },
        {
          type: "paragraph",
          text: "Kennen Sie Immobilien mit schlechter Präsentation, die monatelang nicht verkauft werden? Dies muß unbedingt vermieden werden.",
        },
        {
          type: "paragraph",
          text: "Folgende Faktoren sind für die erfolgreiche Vermarktung Ihrer Immobilie besonders wichtig:",
        },
        {
          type: "list",
          columns: 1,

          items: [
            "Kaufpreis: Sie sollten diesen exakt einschätzen können. Der Immobilienmarkt ist durch das Internet sehr transparent. Ein bereits zum Start des Immobilienverkaufs richtig angesetzter Kaufpreis ist immens wichtig. Bei einem falsch angesetzten Kaufpreis verlieren Sie Geld.",
            "Präsentation: Handyfotos sollten Sie unbedingt vermeiden. Präsentieren Sie Ihre Immobilie mit einer hochwertigen Kamera im besten Licht und heben Sie wichtige Details hervor. Das weckt Emotionen beim Kaufinteressenten. Bei Häusern mit Grundstück können Drohnenfotos sinnvoll sein. Zudem ist eine virtuelle 360°-Onlinebesichtigung sehr wichtig.",
            "Informationen: Das Exposé Ihrer Immobilie muß aussagekräftig formuliert sein und auf sämtliche wertrelevanten Eigenschaften hinweisen.",
            "Vermarktungskanäle: Die Immobilie in der Zeitung oder auf einem Immobilienportal anzubieten, reicht nicht aus. Zahlreiche Vermarktungskanäle müssen genutzt werden. Ein großes Netzwerk mit sämtlichen Vermarktungskanälen bietet Ihnen die besten Erfolgschancen.",
          ],
        },
        {
          type: "button",
          text: "Unverbindliche Beratung",
          href: "/kontakt",
        },
      ],
    },
  ],
  frequentlyAskedQuestions: [
    {
      title: "Wann ist der beste Zeitpunkt für den Immobilienverkauf?",
      textBlocks: [
        {
          type: "paragraph",
          text: "Den besten Zeitpunkt für den Immobilienverkauf zu bestimmen ist nicht einfach. Eine genaue Kenntnis des örtlichen Immobilienmarkts ist hierfür notwendig. Welche Nachfrage gibt es derzeit beim jeweiligen Immobilientyp? Welche Kaufpreisentwicklung gab es in den letzten Jahren und welche Zukunftsprognosen gibt es? Zudem bedarf es einer umfassenden Analyse vieler weiterer Faktoren, um den Zeitpunkt zu bestimmen, an dem der optimale Kaufpreis erzielt werden kann.",
        },
      ],
      expanded: true,
    },
    {
      title: "Sollte ich privat oder mit einem Immobilienmakler verkaufen?",
      textBlocks: [
        {
          type: "paragraph",
          text: "Ein Immobilienverkauf ist ein komplexer und anspruchsvoller Prozess. Dies bedeutet einen hohen Arbeits- und Zeitaufwand sowie den Einsatz umfangreicher, technischer Ressourcen. Um einen guten Kaufpreis für Ihre Immobilie zu erzielen, ist dies unabdingbar.",
        },
        {
          type: "paragraph",
          text: "Sie sollten genau wissen, was Sie tun. Bereits kleine Fehler bei der Vermarktung mindern den erzielbaren Kaufpreis erheblich. Der Verlust kann die Maklerprovision bei weitem übersteigen.",
        },
        {
          type: "paragraph",
          text: "Die Erstellung eines eindrucksvollen Exposés benötigt, neben vielen technischen Ressourcen, großes Präsentationsgeschick. Zudem ist Organisationstalent wichtig, weil ein erhebliches Kommunikationsaufkommen beim Kontakt mit den Kaufinteressenten entsteht und Besichtigungstermine persönlich wahrgenommen werden müssen. Auch Ihr Verhandlungsgeschick ist entscheidend, ob Sie die aufwendig gefundenen Kaufinteressenten von Ihrem Kaufpreis überzeugen können.",
        },
        {
          type: "paragraph",
          text: "Sofern Sie nicht über viel Erfahrung beim Immobilienverkauf verfügen, lohnt sich ein Immobilienmakler. Sie sparen sehr viel Zeit- und Arbeitsaufwand und können über die professionelle Vermarktung Ihrer Immobilie einen wesentlich besseren Kaufpreis erzielen.",
        },
        {
          type: "paragraph",
          text: "Wir bieten Ihnen langjährige Erfahrung bei der Immobilienbewertung. Zudem erstellen wir eindrucksvolle Exposés, die auf vielen Marketingkanälen professionell präsentiert werden. Wir verwirklichen auch technisch anspruchsvolle Marketingmaßnahmen, wie eine virtuelle 360°-Onlinebesichtigung. Durch automatisierte Abläufe bearbeiten wir Anfragen von Kaufinteressenten umgehend an allen 365 Tagen im Jahr. Besichtigungstermine werden von uns koordiniert und durchgeführt. Wir kennen die Zielgruppe Ihrer Immobilie und den örtlichen Immobilienmarkt genau. Den Umgang mit schwierigen Kaufinteressenten und kritischen Fragen zur Immobilie sind wir gewohnt. So fällt es uns leicht, die Verkaufsverhandlungen erfolgreich zu führen.",
        },
        {
          type: "paragraph",
          text: "Lehnen Sie sich entspannt zurück. Sobald ernsthafte Kaufinteressenten gefunden sind, entscheiden Sie über die Vergabe Ihrer Immobilie an den richtigen Käufer.",
        },
      ],
    },
    {
      title: "Wie lange dauert ein Immobilienverkauf?",
      textBlocks: [
        {
          type: "paragraph",
          text: "Je nach Immobilie und aktueller Marktlage kann dies sehr unterschiedlich sein. Eine professionelle Vermarktungsstrategie verkürzt die Vermarktungsdauer Ihrer Immobilie jedoch erheblich. Als Experten am örtlichen Immobilienmarkt können wir Ihr Immobilienangebot passend auf die richtige Zielgruppe ausrichten. Zudem trägt zu einer zeitnahen Immobilienvermittlung unser großes Netzwerk bestehend aus immobiliensuchenden Bestandskunden, zahlreichen Immobilienportalen und renommierten Partnerunternehmen bei. So finden wir oftmals kurzfristig den optimalen Käufer.",
        },
      ],
    },
    {
      title: "Mit einem Immobilienmakler auf der sicheren Seite.",
      textBlocks: [
        {
          type: "paragraph",
          text: "Beim Immobilienverkauf gibt es zahlreiche Stolperfallen. Um diese zu vermeiden ist ein Immobilienmakler mit langjähriger Erfahrung wichtig. Wir bieten Ihnen Sicherheit durch umfassende Informationen.",
        },
        {
          type: "paragraph",
          text: "Wir schaffen für Sie einen reibungslosen Verkaufsprozess vom Erstkontakt mit uns, über die professionelle Vermarktung Ihrer Immobilie und die Kaufvertragserstellung bei einem renommierten Notar, bis hin zur Übergabe der Immobilie an den Käufer.",
        },
        {
          type: "paragraph",
          text: "Eine vertrauensvolle Zusammenarbeit garantieren wir Ihnen.",
        },
      ],
    },
  ],
  imageBanner: {
    src: "/images/backgrounds/background-home-3.webp",
    description: "Professionelle Immobilienbewertung",
    title: "Professionelle Marktpreiseinschätzung",
    srcSet:
      "/images/backgrounds/background-home-3-klein.webp 480w, /images/backgrounds/background-home-3-mittel.webp 960w, /images/backgrounds/background-home-3.webp 1920w",
  },
  additionalAdvisory: {
    heading: "Wir beraten Sie jederzeit gerne.",
    button: {
      content: ["Unverbindliche Beratung", "/kontakt"],
      direction: "center",
    },
  },
  advisoryServices: [
    {
      statement: [
        {
          type: "paragraph",
          text: "Gerne informieren wir Sie zu zahlreichen Themen rund um Ihren geplanten Immobilienverkauf. Bei Bedarf bieten wir Ihnen auch gerne den Kontakt zu einem direkten Ansprechpartner bei einem unserer renommierten Kooperationspartner aus dem jeweiligen Fachbereich (Handwerk, Baubranche, Notar, Architekt, etc.).",
        },
        {
          type: "list",
          columns: 3,
          listType: "'✓'",
          items: [
            "Immobilienverkauf bei Scheidung",
            "Immobilienverkauf im Alter",
            "Immobilientausch",
            "Bausachverständiger Freiburg",
            "Umzugsservice Freiburg",
            "Immobilienverkauf bei Erbengemeinschaft",
            "Energieberater Freiburg",
            "Immobilienverkauf an Mieter",
            "Immobilien Dreisamtal",
            "Wohnung bewerten Freiburg",
            "Haus bewerten Freiburg",
            "Immobilie sanieren",
            "Immobilie renovieren",
            "Immobiliensachverständiger Freiburg",
            "Immobilie Notar Freiburg",
            "Architekt Freiburg",
            "Umnutzung Immobilie",
            "Aufteilung Immobilie",
            "Wohnung tauschen",
            "Haus tauschen",
            "Hausbau Freiburg",
            "energetische Sanierung Immobilie",
            "und viele Themenbereiche mehr",
          ],
        },
      ],
    },
  ],
  reasonsToBuy: [
    {
      heading: "Hohe Kundenzufriedenheit",
      text: "Gemeinsam erreichen wir Ihre Ziele. Ihre Zufriedenheit steht dabei für uns im Vordergrund. Wir bieten Ihnen eine hochwertige und engagierte Vermittlung Ihrer Immobilie.",
      image: {
        src: "images/abzeichen/zufriedenheit.webp",
        description: "Wir bieten Ihnen eine Zufriedenheitsgarantie",
        title: "Hohe Kundenzufriedenheit",
        srcSet:
          "images/abzeichen/zufriedenheit-klein.webp 420w, images/abzeichen/zufriedenheit-mittel.webp 840w, images/abzeichen/zufriedenheit.webp 1260w",
        sizes: "(max-width:480px) 420px",
      },
    },
    {
      heading: "Ausgezeichneter Service",
      text: "Hohe Beratungsqualität, zielführendes Handeln und individuellen Service vereinen wir zu einem erfolgreichen Gesamtkonzept. Profitieren Sie von unserer langjährigen Erfahrung beim örtlichen Immobilienmarkt.",
      image: {
        src: "images/abzeichen/service.webp",
        description: "Wir bieten Ihnen den besten Service",
        title: "Ausgezeichneter Service",
        srcSet:
          "images/abzeichen/service-klein.webp 420w, images/abzeichen/service-mittel.webp 840w, images/abzeichen/service.webp 1260w",
        sizes: "(max-width:480px) 420px",
      },
    },
  ],
  textbox: {
    heading: "Qualifizierte Leistung",
    text: [
      "Wir bieten Ihnen umfassenden Service aus einer Hand - kompetent, individuell, seriös.",
    ],
  },
};
