import React from "react";

const RealEstateContext = React.createContext();

const initialState = {
  loading: false,
  error: false,
  fetched: false,
  data: null,
};

function sortRealEstateObjects(realEstateObjects) {
  return realEstateObjects
    .sort((a, b) => {
      if (Number(a.unit_id) > Number(b.unit_id)) {
        return -1;
      }
      if (Number(a.unit_id) < Number(b.unit_id)) {
        return 1;
      }
      if (Number(a.unit_id) === Number(b.unit_id)) {
        return 0;
      }
    })
    .sort((a, b) => {
      if (Number(a.exposee_id) > Number(b.exposee_id)) {
        return 1;
      }
      if (Number(a.exposee_id) < Number(b.exposee_id)) {
        return -1;
      }
      if (Number(a.exposee_id) === Number(b.exposee_id)) {
        return 0;
      }
    });
}

function realEstateReducer(state, action) {
  switch (action.type) {
    case "set error": {
      return { ...state, error: action.payload };
    }
    case "set loading": {
      return { ...state, loading: action.payload };
    }
    case "set data": {
      return {
        ...state,
        fetched: true,
        loading: false,
        data: sortRealEstateObjects(action.payload),
      };
    }
  }
}

function RealEstateProvider({ children }) {
  const [state, dispatch] = React.useReducer(realEstateReducer, initialState);

  const fetchRealEstateEntries = async () => {
    dispatch({ type: "set loading", payload: true });
    const realEstateObjects = await fetch(`https://api.propstack.de/v1/units?per=${process.env.REACT_APP_PROPSTACK_API_REALESTATE_COUNT}&sort_by=exposee_id&expand=1`, {
      method: "GET",
      headers: { "X-API-KEY": process.env.REACT_APP_PROPSTACK_API_KEY },
    }).then((response) => response.json())


    const filteredRealEstateObjects = realEstateObjects.filter((object) => Boolean(object.exposee_id))

    // API show be called two times in case of a large number of real estate objects - for now one call is more performant
    // const detailedRealEstateObjects = await fetch(`https://api.propstack.de/v1/units?expand=1&property_ids=${filteredHighLevelRealEstateObjects.toString()}`, {
    //   method: "GET",
    //   headers: { "X-API-KEY": process.env.REACT_APP_PROPSTACK_API_KEY },
    // }).then((response) => response.json())

    dispatch({ type: "set data", payload: filteredRealEstateObjects });
  };

  const value = { state, dispatch, fetchRealEstateEntries };
  return (
    <RealEstateContext.Provider value={value}>
      {children}
    </RealEstateContext.Provider>
  );
}

function useRealEstate() {
  const context = React.useContext(RealEstateContext);
  if (context === undefined) {
    throw new Error("useRealEstate must be used within a RealEstateProvider");
  }
  return context;
}

export { RealEstateProvider, useRealEstate };
