import React, { lazy, Suspense } from "react";

// components
import Container from "../components/Container";
import ColumnsLayout from "../components/ColumnsLayout";
import Textbox from "../components/Textbox";
import TextWithButton from "../components/TextWithButton";
import ImageBanner from "../components/ImageBanner";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions";
import Table from "../components/Table";
import MapImage from "../components/MapImage";
import ColumnsLayoutHorizontal from "../components/ColumnsLayoutHorizontal";

//data
import { realEstateValuation } from "../data/realEstateValuation";
import Hero from "../components/Hero";

const Leadgenerator = lazy(() =>
  import("../apps/leadGenerator/components/Leadgenerator")
);

export default function RealEstateValuation() {
  return (
    <Container main>
      <Hero
        content={realEstateValuation.hero}
        style={{ textAlign: "center" }}
      />
      <ColumnsLayout content={realEstateValuation.columnsLayout} />
      <TextWithButton
        content={realEstateValuation.description}
        style={{ textAlign: "center" }}
      />
      <Suspense fallback={false}>
        <Leadgenerator description={false} />
      </Suspense>
      <TextWithButton
        content={realEstateValuation.advisory}
        style={{ textAlign: "center" }}
      />
      <MapImage content={realEstateValuation.mapImage} />
      <Table content={realEstateValuation.mapInformation} />
      <FrequentlyAskedQuestions
        content={realEstateValuation.frequentlyAskedQuestions}
      />
      <ImageBanner image={realEstateValuation.imageBanner} />
      <TextWithButton
        content={realEstateValuation.additionalAdvisory}
        style={{ textAlign: "center" }}
      />
      <Table content={realEstateValuation.advisoryServices} />
      <ColumnsLayoutHorizontal content={realEstateValuation.reasonsToBuy} />
      <Textbox content={realEstateValuation.textbox} />
    </Container>
  );
}
