import React, { lazy, useEffect } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import RealEstateValuation from "./pages/RealEstateValuation";
import SellProcess from "./pages/SellProcess";

const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Home = lazy(() => import("./pages/Home"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const LandingPage2 = lazy(() => import("./pages/LandingPage-2"));
const LandingPage3 = lazy(() => import("./pages/LandingPage-3"));
const LandingPage4 = lazy(() => import("./pages/LandingPage-4"));
const LandingPage5 = lazy(() => import("./pages/LandingPage-5"));
const LandingPage6 = lazy(() => import("./pages/LandingPage-6"));
const LandingPage7 = lazy(() => import("./pages/LandingPage-7"));
const LandingPage8 = lazy(() => import("./pages/LandingPage-8"));
const LandingPage9 = lazy(() => import("./pages/LandingPage-9"));
const LandingPage10 = lazy(() => import("./pages/LandingPage-10"));
const LegalNotice = lazy(() => import("./pages/LegalNotice"));
const RealEstate = lazy(() => import("./pages/RealEstate"));
const References = lazy(() => import("./pages/References"));
const Services = lazy(() => import("./pages/Services"));
const ServicesPage2 = lazy(() => import("./pages/Services-2"));
const ServicesPage3 = lazy(() => import("./pages/Services-3"));
const InformationTestimonials = lazy(() =>
  import("./pages/InformationTestimonials")
);
const InformationRanking = lazy(() => import("./pages/InformationRanking"));
const Tipster = lazy(() => import("./pages/Tipster"));
const Leasing = lazy(() => import("./pages/Leasing"));

export default function Router() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/lp1" element={<LandingPage />} />
      <Route path="/lp2" element={<LandingPage2 />} />
      <Route path="/lp3" element={<LandingPage3 />} />
      <Route path="/lp4" element={<LandingPage4 />} />
      <Route path="/lp5" element={<LandingPage5 />} />
      <Route path="/lp6" element={<LandingPage6 />} />
      <Route path="/lp7" element={<LandingPage7 />} />
      <Route path="/lp8" element={<LandingPage8 />} />
      <Route path="/wertermittlung" element={<LandingPage9 />} />
      <Route path="/lp10" element={<LandingPage10 />} />
      <Route path="ueber-uns" element={<About />} />
      <Route path="immobilien/:realEstateId" element={<RealEstate />} />
      <Route path="leistungen" element={<Services />} />
      <Route path="leistungen-2" element={<ServicesPage2 />} />
      <Route path="3d-grundriss" element={<ServicesPage3 />} />
      <Route path="referenzen" element={<References />} />
      <Route path="kontakt" element={<Contact />} />
      <Route path="impressum" element={<LegalNotice />} />
      <Route path="infobewertungen" element={<InformationTestimonials />} />
      <Route path="inforanking" element={<InformationRanking />} />
      <Route path="tippgeber" element={<Tipster />} />
      <Route path="immobilienbewertung" element={<RealEstateValuation />} />
      <Route path="verkaufsprozess" element={<SellProcess />} />
      <Route path="vermietung" element={<Leasing />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
