import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// components
import Container from "./Container";

export default function Hero(props) {
  const { content, style } = props;

  return (
    <Container>
      <WidthWrapper>
        {content.heading && (
          <Heading style={style}>
            <span>{content.heading[0]}</span>
            <span
              dangerouslySetInnerHTML={{ __html: content.heading.slice(1) }}
            />
          </Heading>
        )}

        {content.subheading && (
          <Subheading style={style}>{content.subheading}</Subheading>
        )}
        {content.text &&
          content.text.map((paragraph, index) => (
            <Text
              key={paragraph + index}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
        {content.image &&
          content.image.map((img, index) => (
            <ImageWrapper key={img + index}>
              <Image
                src={img.src}
                alt={img.description}
                loading="lazy"
                srcSet={img.srcSet && img.srcSet}
                sizes={img.sizes && img.sizes}
              />
            </ImageWrapper>
          ))}
      </WidthWrapper>
    </Container>
  );
}

const WidthWrapper = styled.div`
  max-width: 1440px;

  padding: 0 30px;
  margin: 30px auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Heading = styled.h2`
  color: #393a3c;
  font-size: 24px;
  font-weight: 400;

  span:first-child {
    font-size: 2em;
  }
`;

const Subheading = styled.p`
  font-size: medium;
  color: #777;
  margin: 6px 0;
  b {
    font-family: Montserrat, serif;
  }
`;

const ImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
`;

const Text = styled.p`
  color: #777;
  hyphens: auto;
  text-align: center;
  margin: 4px 0;
  b {
    font-family: Montserrat, sans-serif;
  }
  @media (max-width: 480px) {
    text-align: justify;
  }
`;

Hero.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      })
    ),
    text: PropTypes.arrayOf(PropTypes.string),
    secondImage: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      })
    ),
    button: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
