import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// components
import Container from "../components/Container";

export default function Footer(props) {
  const { content } = props;

  return (
    <Container footer>
      {content.map((column) => (
        <Column key={column.heading}>
          <Heading>{column.heading}</Heading>
          {column.button && (
            <Button to={column.button.href}>{column.button.text}</Button>
          )}
          {column.images?.map((image, index) => (
            <Image
              key={image.src + index}
              src={image.src}
              alt={image.description || ""}
              title={image.title}
              width={image.width}
              height={image.height}
              loading="lazy"
              srcSet={image.srcSet && image.srcSet}
              sizes={image.sizes && image.sizes}
            />
          ))}
          {column.data?.map((item, index) => (
            <Item key={item.name + index}>
              {item.icon && <Icon>{item.icon}</Icon>}
              <Data>
                {item.name && (
                  <Name>
                    {item.name.href ? (
                      <a href={item.name.href}>{item.name.text}</a>
                    ) : (
                      item.name.text
                    )}
                  </Name>
                )}
                {item.value && (
                  <Value index={index}>
                    {item.value?.href ? (
                      <a href={item.value.href}>{item.value.text}</a>
                    ) : (
                      item.value.text
                    )}
                  </Value>
                )}
              </Data>
            </Item>
          ))}
        </Column>
      ))}
    </Container>
  );
}

const Column = styled.div`
  flex: 0 0 33%;
  padding: 30px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1200px) {
    flex: 0 0 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow: hidden;

    &:nth-child(2) {
      padding-bottom: 100px;
    }
  }
`;

const Heading = styled.h4`
  position: relative;
  color: #fff;
  margin: 50px 0 30px 0;
  font-size: 16px;

  &::after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #d4d5d6;
    width: 100%;
    max-width: 1700px;
    bottom: 0;
    left: 0;
  }
`;

const Image = styled.img`
  display: block;
  margin-bottom: 20px;
  max-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  color: #fff;
  margin-right: 15px;
`;

const Data = styled.div`
  color: #fff;
`;

const Name = styled.div`
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
`;

const Value = styled.div`
  color: #dcdcdc;
  transition: 0.3s all ease;

  &:hover {
    color: #c3c3c3;
  }

  a {
    color: ${(props) => (props.index === 0 ? "#dcdcdc" : "#58a313")};
    text-decoration: none;
    transition: 0.3s all ease;

    &:hover {
      color: ${(props) => (props.index === 0 ? "#c3c3c3" : "#ac804e")};
      text-decoration: underline;
    }
  }
`;

const Button = styled(Link)`
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 47px;
  transition: 0.3s all ease;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: 0.08em;
  position: absolute;
  top: 160px;
  left: 230px;

  &:hover {
    background-color: #58a313;
    border: 2px solid #58a313;
  }

  @media (max-width: 1400px) {
    padding: 9px 25px;
    top: 170px;
    left: 215px;
  }

  @media (max-width: 1200px) {
    padding: 15px 47px;
    left: 30px;
    top: 550px;
  }
`;

Footer.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          description: PropTypes.string,
          title: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        })
      ),
      button: PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.object,
          name: PropTypes.shape({
            text: PropTypes.string.isRequired,
            href: PropTypes.string,
          }),
          value: PropTypes.shape({
            text: PropTypes.string.isRequired,
            href: PropTypes.string,
          }),
        })
      ),
    })
  ).isRequired,
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  genuineReviewHint: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  }),
};
