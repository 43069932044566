import React, { useState } from "react";

// components
import ContactHeader from "./ContactHeader";
import HeaderMenu from "./HeaderMenu";

// data
import header from "../data/header";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleIconClick = (icon) => {
    if (icon === "menu") {
      setMenuOpen(!menuOpen);
      setPopupOpen(false);
    }
    if (icon === "popup") {
      setMenuOpen(false);
      setPopupOpen(!popupOpen);
    }
  };

  return (
    <>
      <ContactHeader content={header.contact} popupOpen={popupOpen} />
      <HeaderMenu
        logo={header.logo}
        nav={header.menu}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        handleIconClick={handleIconClick}
      />
    </>
  );
}
