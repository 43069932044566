import React from "react";
import styled from "styled-components";

// components
import Container from "../components/Container";

export default function ContactHeader(props) {
  const { content, popupOpen } = props;

  return (
    <Container contactHeader popupOpen={popupOpen}>
      {content.map((item, index) => (
        <Item key={index}>
          {item.icon}{" "}
          {item.text.length > 1 ? (
            <a href={item.text[1]}>{item.text[0]}</a>
          ) : (
            item.text[0]
          )}
        </Item>
      ))}
    </Container>
  );
}

const Item = styled.div`
  display: flex;
  margin-right: 55px;
  align-items: center;
  color: #dcdcdc;

  @media (max-width: 1400px) {
    color: #fff;
    margin-bottom: 15px;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: 1400px) {
      margin-bottom: 0;
    }
  }

  svg {
    margin-right: 15px;
  }

  a {
    color: #dcdcdc;
    text-decoration: none;
    transition: 0.3s all ease;

    &:hover {
      text-decoration: underline;
      color: #c3c3c3;
    }

    @media (max-width: 1400px) {
      color: #fff;
    }
  }
`;
