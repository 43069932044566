import React from "react";
import styled from "styled-components";

export default function StepDescription({ content }) {
  return (
    <Container>
      <Wrapper>
        {content.header && (
          <Header>
            <span>{content.header[0]}</span>
            <span>{content.header.slice(1)}</span>
          </Header>
        )}
        {content.description && (
          <>
            {content.description.map((paragraph) => (
              <Description>{paragraph}</Description>
            ))}
          </>
        )}
        {content.stepBlocks.map((stepBlock) => (
          <StepBlock>
            <Header>
              <span>{stepBlock.title[0]}</span>
              <span>{stepBlock.title.slice(1)}</span>
            </Header>
            <Items>
              {stepBlock.steps.map((step, index) => (
                <Item key={index}>
                  {step.image && (
                    <ImageWrapper>
                      <Image
                        src={step.image.src}
                        alt={step.image.description || ""}
                        title={step.image.title || ""}
                        loading="lazy"
                        srcSet={step.image.srcSet && step.image.srcSet}
                        sizes={step.image.sizes && step.image.sizes}
                      />
                    </ImageWrapper>
                  )}
                  {step.title && <Title>{step.title}</Title>}
                  {step.text &&
                    step.text.map((paragraph) => <Text>{paragraph}</Text>)}
                </Item>
              ))}
            </Items>
          </StepBlock>
        ))}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f4f6f9;
`;

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 480px) {
    max-width: none;
  }
`;

const Header = styled.h2`
  text-align: center;
  color: #393a3c;
  font-size: 24px;
  font-weight: 400;
  margin: 30px 0;

  span:first-child {
    font-size: 2em;
  }
`;

const Description = styled.p`
  text-align: center;
  color: #777;
`;

const Text = styled.p`
  hyphens: auto;
  text-align: justify;
  color: #777;
  margin: 4px 0;
`;

const StepBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Title = styled.h3`
  color: green;
  font-size: large;
  text-decoration: underline;
  text-align: left;
  margin: 20px 0;
`;
