import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function ScrollToTop() {
  const [visibility, setVisibility] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setVisibility(position > 500);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      onClick={() => scroll()}
      isVisible={visibility}
      aria-label="Scroll to top"
    />
  );
}

const Button = styled.button`
  width: 50px;
  height: 50px;
  background-color: #58a313;
  border: none;
  border-radius: 50%;
  position: fixed;
  right: 15px;
  bottom: ${(props) => (props.isVisible ? '15px' : '-50px')};
  z-index: 20;
  transition: 0.3s all ease;
  cursor: pointer;

  &:hover {
    background-color: #be996f;
  }
`;
