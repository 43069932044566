import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import styled from "styled-components";

export default function FrequentlyAskedQuestions({ content }) {
  return (
    <Container>
      {content.map((element, index) => (
        <Accordion
          key={element.title + index}
          defaultExpanded={element.expanded}
          disableGutters
          elevation={0}
          square
          sx={{
            border: `1px solid #d4d5d6`,
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<AddCircleIcon sx={{ color: "green" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordionHeadline>{element.title}</AccordionHeadline>
          </AccordionSummary>
          <AccordionDetails>
            {element.textBlocks.map((block) => (
              <div key={index}>
                {block.type === "paragraph" && <Text>{block.text}</Text>}
                {block.type === "link" && (
                  <ExternalLink key={block.text} href={block.href}>
                    {block.text}
                  </ExternalLink>
                )}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

const AccordionHeadline = styled.p`
  font-family: Montserrat, serif;
  color: #393a3c;
`;

const Text = styled.p`
  color: #777;
  hyphens: auto;
  margin: 4px 0;
  text-align: justify;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 60px auto;
  padding: 20px;
`;

const ExternalLink = styled.a`
  color: #58a313;
  margin: 20px 0;
  &:hover {
    text-decoration: underline;
    color: #ac804e;
  }
`;
