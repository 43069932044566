import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

// icons
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import Container from "./Container";

// helpers
import { useLocation } from "react-router-dom";
import useOnClickOutside from "../helpers/useOnClickOutside";
import useWindowScrollPositions from "../helpers/useWindowScrollPositions";

export default function HeaderMenu(props) {
  const { logo, nav, handleIconClick, menuOpen, setMenuOpen } = props;

  const { pathname } = useLocation();
  const sanitizedPathname = `/${pathname.replace(/[^a-zA-Z]/g, "")}`;

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const { scrollY } = useWindowScrollPositions();

  return (
    <Sticky ref={ref}>
      <Container header downsized={scrollY >= 60}>
        <MobileBar>
          <MenuIcon onClick={() => handleIconClick("menu")} />
          <Logo mobile="true" to="/">
            <span>{logo[0]}</span> {logo[1]}
          </Logo>
          <MoreVertIcon onClick={() => handleIconClick("popup")} />
        </MobileBar>
        <Logo to="/">
          <span>{logo[0]}</span> {logo[1]}
        </Logo>
        <Navbar menuOpen={menuOpen}>
          {nav.map((item, index) => (
            <Item key={index} onClick={() => setMenuOpen(false)}>
              <NavLink
                to={item.href}
                className={item.href === sanitizedPathname ? "active " : ""}
              >
                {item.text}
              </NavLink>
            </Item>
          ))}
        </Navbar>
      </Container>
    </Sticky>
  );
}

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Navbar = styled.div`
  display: flex;

  @media (max-width: 1600px) {
    background-color: #393a3c;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: ${(props) => (props.menuOpen ? "0" : "-280px")};
    z-index: 20;
    height: 100vh;
    width: 280px;
    max-width: 80%;
    transition: 0.3s all ease;
  }
`;

const MobileBar = styled.div`
  display: none;
  background-color: #393a3c;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 21;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;

  svg {
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    display: flex;
  }
`;

const Item = styled.div`
  display: flex;
  margin-right: 17px;
  align-items: center;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1400px) {
    margin-right: 10px;
    margin-left: 10px;

    &:first-child {
      margin-top: 80px;
    }
  }

  a {
    color: #393a3c;
    text-decoration: none;
    transition: 0.3s all ease;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 700;
    font-family: Montserrat, serif;
    font-size: 14px;

    &:hover {
      color: #58a313;

      @media (max-width: 1600px) {
        color: #fff;
      }
    }

    &.active {
      color: #58a313;
    }

    @media (max-width: 1600px) {
      color: #fff;
      width: 100%;
      padding: 15px;

      &.active {
        background-color: rgba(255, 255, 255, 0.36);
        color: #fff;
      }
    }
  }
`;

const Logo = styled(Link)`
  color: #393a3c;
  font-size: 24px;
  line-height: 36px;
  margin-right: 50px;
  text-decoration: none;

  span {
    font-size: 28px;
    line-height: 42px;
    color: #008000;
    font-weight: bold;

    @media (max-width: 1600px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1600px) {
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;

    ${(props) => props.mobile !== "true" && "display: none;"}
  }
`;
