import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function ImageBanner(props) {
  const { image } = props;

  return (
    <Image
      src={image.src}
      alt={image.description}
      title={image.title}
      loading={image.lazy ? "lazy" : "eager"}
      width={image.width && image.width}
      height={image.height && image.height}
      srcSet={image.srcSet && image.srcSet}
      sizes={image.sizes && image.sizes}
    />
  );
}

const Image = styled.img`
  width: 100%;
`;

ImageBanner.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    lazy: PropTypes.bool,
  }),
};
