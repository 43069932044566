import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export default function Container(props) {
  const {
    footer,
    header,
    main,
    contactHeader,
    children,
    popupOpen,
    imageSlider,
    downsized,
  } = props;

  switch (true) {
    case footer:
      return (
        <Footer>
          <div>{children}</div>
        </Footer>
      );
    case header:
      return (
        <Header downsized={downsized}>
          <div>{children}</div>
        </Header>
      );
    case main:
      return (
        <Main>
          <div>{children}</div>
        </Main>
      );
    case imageSlider:
      return <ImageSlider>{children}</ImageSlider>;
    case contactHeader:
      return (
        <ContactHeader popupOpen={popupOpen}>
          <div>{children}</div>
        </ContactHeader>
      );
    default:
      return (
        <Section>
          <div>{children}</div>
        </Section>
      );
  }
}

const baseStyles = css``;

const Footer = styled.footer`
  ${baseStyles};
  background-color: #2f3133;

  & > div {
    display: flex;
    flex-flow: row wrap;
  }
`;

const Header = styled.header`
  ${baseStyles};

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.downsized ? "46px" : "110px")};
    padding: 0 30px;
    background-color: #fff;
    transition: height 0.3s ease;
    box-shadow: ${(props) =>
      props.downsized ? "0 0 7px 0 rgb(0 0 0 / 15%)" : "none"};

    @media (max-width: 1400px) {
      height: 50px;
      box-shadow: none;
    }
  }
`;

const Main = styled.main`
  ${baseStyles};
`;

const ImageSlider = styled.section`
  ${baseStyles};
  position: relative;
`;

const Section = styled.section`
  ${baseStyles};
`;

const ContactHeader = styled.div`
  ${baseStyles};
  background-color: #393a3c;
  padding: 0 30px;

  @media (max-width: 1400px) {
    display: ${(props) => (props.popupOpen ? "flex" : "none")};
    background-color: #58a313;
    position: fixed;
    top: 85px;
    right: 15px;
    width: 350px;
    max-width: calc(100% - 30px);
    z-index: 100;
    padding: 0;
  }

  & > div {
    height: 60px;
    display: flex;
    align-content: center;

    @media (max-width: 1400px) {
      flex-direction: column;
      height: auto;
      padding: 15px;
      box-sizing: border-box;
    }
  }
`;

Container.propTypes = {
  children: PropTypes.any.isRequired,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  contactHeader: PropTypes.bool,
  main: PropTypes.bool,
};
