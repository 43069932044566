import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export default function Video(props) {
  const { video } = props;

  return (
    <VideoContainer>
      <IFrame>
        <LiteYouTubeEmbed
          id={video.id}
          title={video.title}
          aspectHeight={450}
          aspectWidth={800}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IFrame>
      {video.button && (
        <Button to={video.button.href}>{video.button.text}</Button>
      )}
    </VideoContainer>
  );
}

const VideoContainer = styled.section`
  padding: 50px 30px;
  text-align: center;
`;

const IFrame = styled.div`
  max-width: 800px;
  max-height: 450px;
  margin: 0 auto;
  overflow: hidden;
`;

const Button = styled(Link)`
  background-color: #58a313;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  padding: 20px 47px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.08em;
  font-family: Montserrat, serif;
  transition: 0.3s;
  display: inline-block;

  &:hover {
    background-color: #be996f;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 20px 30px;
  }
`;

Video.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    button: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
};
