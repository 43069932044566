import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { RealEstateProvider } from "./context/real-estate-context";

import "./assets/css/global.css";

// components
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./Router";
import Loading from "./components/Loading";

// data
import footer from "./data/footer";

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Header />
        <RealEstateProvider>
          <Router />
        </RealEstateProvider>
        <ScrollToTop />
        <Footer
          content={footer.content}
          nav={footer.nav}
          genuineReviewHint={footer.genuineReviewHint}
        />
      </Suspense>
    </BrowserRouter>
  );
}
