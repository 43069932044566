export const sellProcess = {
  hero: {
    heading: "3 Schritte zum Verkaufserfolg",
    subheading:
      "Lehnen Sie sich entspannt zurück. Wir finden <b>zeitnah den richtigen Käufer</b> für Ihre Immobilie.",
    text: [
      "Wir erledigen für Sie kompetent und zuverlässig sämtliche Arbeiten. <b>Sie haben keinen Zeit- und Arbeitsaufwand.</b>",
      "Eine <b>vertrauensvolle und transparente Zusammenarbeit</b> garantieren wir Ihnen.",
    ],
    applyTextStyle: true,
    secondImage: [
      {
        src: "/images/sparschwein.webp",
        sizes: "(max-width:480px) 175px",
        description: "Sparschwein",
        title: "Geld sparen",
      },
    ],
    button: {
      content: ["Unverbindliche Beratung", "/kontakt"],
      direction: "center",
    },
  },
  stepDescription: {
    stepBlocks: [
      {
        title: "1. Schritt",
        steps: [
          {
            title: "Marktpreiseinschätzung anfordern",
            image: {
              src: "/images/leistungen/va1.webp",
              sizes: "(max-width:480px) 300px",
              description: "telefonischer Support",
              title: "Immobilienbewertung jetzt anfordern",
            },
            text: [
              "Verkaufen Sie Ihre Immobilie nicht unter Wert. Sie erhalten zeitnah und unverbindlich eine professionelle Marktpreiseinschätzung Ihrer Immobilie von unseren Experten aus der Region.",
              "Anschließend entscheiden Sie, ob Sie Ihre Immobilie selbständig oder mit unserer professionellen Unterstützung als qualifizierter Immobilienmakler verkaufen möchten.",
            ],
          },
          {
            title: "Vertrauensvolle Zusammenarbeit",
            image: {
              src: "/images/leistungen/lvz16.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "vertrauensvolle Zusammenarbeit",
            },
            text: [
              "Wir sorgen für einen reibungslosen Verkaufsprozess und erstellen für Ihre Immobilie eine individuelle Vermarktungsstrategie.",
              "Eine vertrauensvolle Zusammenarbeit garantieren wir Ihnen; ebenso Transparenz bei jedem Arbeitsschritt. Ab jetzt haben Sie einen verlässlichen und kompetenten Partner an Ihrer Seite.",
            ],
          },
          {
            title: "Eindrucksvolles Exposé",
            image: {
              src: "/images/leistungen/lvz5.webp",
              sizes: "(max-width:480px) 300px",
              description: "hochwertige Immobilienfotos",
              title: "eindrucksvolles Exposé",
            },
            text: [
              "Um Ihre Immobilie im besten Licht zu präsentieren, erstellen wir hochwertige Fotos, eine virtuelle 360°-Onlinebesichtigung, möblierte 3D-Grundrisse und vieles mehr.",
              "Zusammengefasst wird dies in einem eindrucksvollen Exposé mit ausführlicher Beschreibung sämtlicher Vorteile Ihrer Immobilie.",
            ],
          },
        ],
      },
      {
        title: "2. Schritt",
        steps: [
          {
            title: "Professionelle Vermarktung",
            image: {
              src: "/images/leistungen/lvz4.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Professionelle Vermarktung",
            },
            text: [
              "Wir präsentieren Ihre Immobilie optimal und zielgruppengerecht in unserem großen Netzwerk aus Bestandskunden, renommierten Immobilienportalen und Partnerunternehmen.",
              "Somit ist sichergestellt, daß Ihr Immobilienangebot viele Kaufinteressenten erreicht. Über den Vermarktungsstand werden Sie stets informiert.",
            ],
          },
          {
            title: "Zeitsparende Besichtigungen",
            image: {
              src: "/images/leistungen/va5.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Zeitsparende Besichtigungen",
            },
            text: [
              "Sie können sich entspannt zurücklehnen. Wir führen die Besichtigungen durch. Bereits im Vorfeld wählen wir hierfür ernsthafte und finanzierungsfähige Kaufinteressenten aus.",
              "Es finden hintereinandergeschaltete Einzelbesichtigungen statt - gebündelt an einem Wochentag Ihrer Wahl. Das spart Ihnen viel Zeit und schützt Ihre Privatsphäre.",
            ],
          },
          {
            title: "Bonitätsprüfung Kaufinteressenten",
            image: {
              src: "/images/leistungen/lvz15.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Bonitätsprüfung Kaufinteressenten",
            },
            text: [
              "Um einen reibungslosen Immobilienverkauf zu gewährleisten, lassen wir die Bonität der Kaufinteressenten prüfen.",
              "Auf Wunsch erhalten Kaufinteressenten ein Baufinanzierungsangebot mit aktuellen Top-Konditionen von einem renommierten Partnerunternehmen.",
            ],
          },
        ],
      },
      {
        title: "3. Schritt",
        steps: [
          {
            title: "Abschließende Vertragsverhandlungen",
            image: {
              src: "/images/leistungen/lvz10.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Abschließende Vertragsverhandlungen",
            },
            text: [
              "Ein entscheidender Faktor beim erfolgreichen Immobilienverkauf ist unser Verhandlungsgeschick.",
              "Wir übernehmen für Sie sämtliche Vertragsverhandlungen mit den Kaufinteressenten entsprechend Ihren Vorgaben. Mit unserer langjährigen Erfahrung werden wir den Immobilienverkauf erfolgreich für Sie realisieren.",
            ],
          },
          {
            title: "Sichere Vertragsabwicklung",
            image: {
              src: "/images/leistungen/lvz13.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Vertrauensvolle Zusammenarbeit",
            },
            text: [
              "Nachdem Sie den Käufer für Ihre Immobilie ausgewählt haben, erstellt ein renommierter Notar den Kaufvertrag. Sämtliche Ihrer Fragen hierzu klären wir ausführlich im Vorfeld.",
              "Anschließend begleiten wir Sie zur Kaufvertragsunterzeichnung beim Notar.",
            ],
          },
          {
            title: "Übergabe Immobilie",
            image: {
              src: "/images/leistungen/va9.webp",
              sizes: "(max-width:480px) 300px",
              description: "Handschlag Service aus einer Hand",
              title: "Übergabe Immobilie",
            },
            text: [
              "Nachdem Sie den Kaufbetrag vom Immobilienkäufer erhalten haben, führen wir die Übergabe der Immobilie durch und protokollieren wichtige Details.",
              "Damit ist der Verkaufsprozess erfolgreich abgeschlossen.",
            ],
          },
        ],
      },
    ],
  },
  whyIFTSforSelling: {
    heading: "Warum IFTS Immobilien Schmidt e.K. ?",
    text: [
      "Wir bieten Ihnen einen überzeugenden Mehrwert beim Vermittlungserfolg und der Vermittlungsdauer Ihrer Immobilie. Entscheidend ist unsere zielorientierte und langjährig erfolgreiche Vermarktungsstrategie - individuell abgestimmt auf Ihre Immobilie. Mit uns haben Sie einen zuverlässigen und engagierten Partner an Ihrer Seite.",
    ],
  },
  exposeForSelling: {
    heading: "Modernes Web-Exposé",
    text: [
      "Zusätzlich zum herkömmlichen Exposé erhalten Kaufinteressenten Zugriff auf ein modernes Web-Exposé Ihrer Immobilie. Dieses bietet ein komfortables Informationserlebnis und garantiert zusätzliche Aufmerksamkeit für Ihr Immobilienangebot.",
      "Kaufinteressenten wird das Web-Exposé automatisiert von unserer Maklersoftware sofort nach der Anfrage über ein Immobilienportal zugesendet - rund um die Uhr an allen Tagen im Jahr:",
    ],
    link: {
      text: "Web-Exposé einer Beispiel-Immobilie",
      href: "https://crm.propstack.de/angebot/einheit/boyk2uWSjLBaR8PaoWsBQUjz/NfQ8rxCW6h1QSVaKorYUYNXQ?locale=de",
    },
  },
  documentsForSelling: {
    heading: "Welche Dokumente werden beim Immobilienverkauf benötigt?",
    text: [
      "Beim Immobilienverkauf werden zahlreiche Dokumente benötigt. Wir organisieren alle benötigten Dokumente für Sie und bereiten diese bei Bedarf professionell auf:",
    ],
    link: {
      text: "Benötigte Dokumente beim Immobilienverkauf",
      href: "/UnterlagenImmobilienverkauf.pdf",
    },
  },
  video: {
    title: "IFTS Immobilien Schmidt eK Imagevideo HD1080",
    id: "O4Mpn4-Kfho",
  },
  videoDescription: {
    heading: "Eine sichere Entscheidung.",
    subheading: "IFTS Immobilien Schmidt e.K.",
    text: [
      "Wir sind Ihr Immobilienmakler in der Region.",
      "Freiburg im Breisgau - Dreisamtal - Kaiserstuhl - Südlicher Oberrhein - Hochschwarzwald",
    ],
    applyTextStyle: true,
    button: {
      content: ["Unverbindliche Beratung", "/kontakt"],
      direction: "center",
    },
  },
  mapImage: {
    image: {
      src: "/images/landkarte.webp",
      description: "Freiburg Schwarzwald Breisach Kaiserstuhl",
      title: "Ihr Immobilienmakler in der Region.",
    },
    detailImage: "/images/landkarte-gross.webp",
  },
  mapInformation: [
    {
      headline: "Wir unterstützen Sie in allen Städten & Gemeinden der Region",
      statement: [
        {
          type: "list",
          columns: 3,
          listType: "'✓'",
          items: [
            "Freiburg - Altstadt",
            "Freiburg - Herdern",
            "Freiburg - Littenweiler",
            "Freiburg - Betzenhausen",
            "Freiburg - Haslach",
            "Freiburg - Hochdorf",
            "Freiburg - Wiehre",
            "Freiburg - Vauban",
            "Emmendingen",
            "Denzlingen",
            "Herbolzheim",
            "Breisach am Rhein",
            "Neuenburg am Rhein",
            "Bad Krozingen",
            "Kirchzarten",
            "Feldberg Schwarzwald",
            "Titisee-Neustadt",
            "Waldkirch",
            "Endingen am Kaiserstuhl",
            "Elzach",
            "Furtwangen im Schwarzwald",
            "Staufen im Breisgau",
            "viele weitere Städte & Gemeinden",
          ],
        },
      ],
    },
  ],
  reasonsToBuy: [
    {
      heading: "Hohe Kundenzufriedenheit",
      text: "Gemeinsam erreichen wir Ihre Ziele. Ihre Zufriedenheit steht dabei für uns im Vordergrund. Wir bieten Ihnen eine hochwertige und engagierte Vermittlung Ihrer Immobilie.",
      image: {
        src: "images/abzeichen/zufriedenheit.webp",
        description: "Wir bieten Ihnen eine Zufriedenheitsgarantie",
        title: "Hohe Kundenzufriedenheit",
        srcSet:
          "images/abzeichen/zufriedenheit-klein.webp 420w, images/abzeichen/zufriedenheit-mittel.webp 840w, images/abzeichen/zufriedenheit.webp 1260w",
        sizes: "(max-width:480px) 420px",
      },
    },
    {
      heading: "Ausgezeichneter Service",
      text: "Hohe Beratungsqualität, zielführendes Handeln und individuellen Service vereinen wir zu einem erfolgreichen Gesamtkonzept. Profitieren Sie von unserer langjährigen Erfahrung beim örtlichen Immobilienmarkt.",
      image: {
        src: "images/abzeichen/service.webp",
        description: "Wir bieten Ihnen den besten Service",
        title: "Ausgezeichneter Service",
        srcSet:
          "images/abzeichen/service-klein.webp 420w, images/abzeichen/service-mittel.webp 840w, images/abzeichen/service.webp 1260w",
        sizes: "(max-width:480px) 420px",
      },
    },
  ],
  textbox: {
    heading: "Qualifizierte Leistung",
    text: [
      "Wir bieten Ihnen umfassenden Service aus einer Hand - kompetent, individuell, seriös.",
    ],
  },
};
