import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// components
import Container from "./Container";

export default function TextWithButton(props) {
  const { content, style } = props;

  return (
    <Container>
      <WidthWrapper>
        {content.heading && (
          <Heading style={style}>
            <span>{content.heading[0]}</span>
            <span
              dangerouslySetInnerHTML={{ __html: content.heading.slice(1) }}
            />
          </Heading>
        )}

        {content.subheading && (
          <Subheading
            style={style}
            dangerouslySetInnerHTML={{ __html: content.subheading }}
          />
        )}

        {content.image &&
          content.image.map((img, index) => (
            <ImageWrapper key={img + index} direction={img.direction}>
              <Image
                src={img.src}
                alt={img.description}
                loading="lazy"
                srcSet={img.srcSet && img.srcSet}
                sizes={img.sizes && img.sizes}
                width={img.width && img.width}
                height={img.height && img.height}
              />
            </ImageWrapper>
          ))}
        {content.text &&
          content.text.map((paragraph, index) => (
            <Text
              key={paragraph + index}
              style={content.applyTextStyle && style}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
        {content.secondImage &&
          content.secondImage.map((img, index) => (
            <SecondImageWrapper key={img + index}>
              <SecondImage
                src={img.src}
                alt={img.description}
                loading="lazy"
                srcSet={img.srcSet && img.srcSet}
                sizes={img.sizes && img.sizes}
                width={img.width && img.width}
                height={img.height && img.height}
              />
            </SecondImageWrapper>
          ))}
        {content.button && (
          <ButtonWrapper direction={content.button.direction}>
            <Button to={content.button.content[1]}>
              {content.button.content[0]}
            </Button>
          </ButtonWrapper>
        )}
        {content.phone && (
          <ButtonWrapper direction={content.phone.direction}>
            <Phone href={content.phone.content[1]}>
              {content.phone.content[0]}
            </Phone>
          </ButtonWrapper>
        )}
        {content.link && (
          <DownloadLink href={content.link.href}>
            {content.link.text}
          </DownloadLink>
        )}
      </WidthWrapper>
    </Container>
  );
}

const WidthWrapper = styled.div`
  max-width: 1200px;

  padding: 0 30px;
  margin: 30px auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Heading = styled.h2`
  color: #393a3c;
  font-size: 24px;
  font-weight: 400;

  span:first-child {
    font-size: 2em;
  }
`;

const Subheading = styled.p`
  font-size: medium;
  color: #777;
  margin: 4px 0;
  b {
    font-family: Montserrat, serif;
  }
`;

const ImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: ${(props) =>
    props.direction ? (props) => props.direction : "left"};

  @media (max-width: 480px) {
    margin: 10px 0;
    justify-content: center;
  }
`;

const SecondImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
`;

const SecondImage = styled.img`
  width: 100%;
  max-width: 150px;
  height: auto;
`;

const Text = styled.p`
  color: #777;
  hyphens: auto;
  text-align: justify;
  margin: 4px 0;
  b {
    font-family: Montserrat, sans-serif;
  }
`;

const ButtonWrapper = styled.div`
  text-align: ${(props) => (props.direction === "center" ? "center" : "left")};
`;

const Button = styled(Link)`
  background-color: #58a313;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 16px;
  padding: 20px 47px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.08em;
  font-family: Montserrat, sans-serif;
  transition: 0.3s;
  display: inline-block;

  &:hover {
    background-color: #be996f;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 20px 30px;
  }
`;

const Phone = styled.a`
  background-color: #58a313;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 16px;
  padding: 20px 47px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.08em;
  font-family: Montserrat, sans-serif;
  transition: 0.3s;
  display: inline-block;

  &:hover {
    background-color: #be996f;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 20px 30px;
  }
`;

const DownloadLink = styled.a`
  color: #58a313;

  &:hover {
    text-decoration: underline;
    color: #ac804e;
  }
`;

TextWithButton.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      })
    ),
    text: PropTypes.arrayOf(PropTypes.string),
    secondImage: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      })
    ),
    button: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.string),
      direction: PropTypes.string,
    }),
  }).isRequired,
};
