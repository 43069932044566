import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Table({ content }) {
  return (
    <TableWrapper>
      {content.map((tableEntry, index) => (
        <table key={index}>
          {tableEntry.headline && (
            <Header>
              <tr>
                <th>{tableEntry.headline}</th>
              </tr>
            </Header>
          )}
          <tbody>
            <tr>
              <Body>
                {tableEntry.statement &&
                  tableEntry.statement.map((element, index) => (
                    <div key={index}>
                      {element.type === "paragraph" && (
                        <Text
                          key={element.text}
                          dangerouslySetInnerHTML={{ __html: element.text }}
                        />
                      )}
                      {element.type === "button" && (
                        <div style={{ textAlign: "center", margin: "60px 0" }}>
                          <Button
                            key={element.text}
                            href={element.href}
                            download={element.download}
                          >
                            {element.text}
                          </Button>
                        </div>
                      )}
                      {element.type === "link" && (
                        <ExternalLink key={element.text} href={element.href}>
                          {element.text}
                        </ExternalLink>
                      )}
                      {element.type === "list" && (
                        <List
                          columns={element.columns}
                          listType={element.listType}
                        >
                          {element.items.map((item, index) => (
                            <li key={item + index}>{item}</li>
                          ))}
                        </List>
                      )}
                    </div>
                  ))}
                {tableEntry.link && (
                  <GenuineLink to={tableEntry.link.href}>
                    {tableEntry.link.text}
                  </GenuineLink>
                )}
                {tableEntry.image && (
                  <ImageWrapper>
                    <Image
                      src={tableEntry.image.src}
                      alt={tableEntry.image.description}
                      title={tableEntry.image.title}
                      loading="lazy"
                      srcSet={
                        tableEntry.image.srcSet && tableEntry.image.srcSet
                      }
                      sizes={tableEntry.image.sizes && tableEntry.image.sizes}
                    />
                  </ImageWrapper>
                )}
              </Body>
            </tr>
          </tbody>
        </table>
      ))}
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  th {
    border-bottom: black 1px solid;
    padding: 20px;
    text-align: left;
    font-size: 19.2px;
    font-family: Montserrat, serif;
  }
`;

const Header = styled.thead`
  th {
    border-bottom: black 1px solid;
    padding: 20px;
    text-align: left;
    font-size: 19.2px;
    font-family: Montserrat, serif;
  }
`;

const Body = styled.td`
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 20px;
`;

const Text = styled.p`
  color: #777;
  margin: 4px 0;
  hyphens: auto;
  text-align: justify;
`;

const Button = styled.a`
  background-color: #58a313;
  color: #fff;
  margin: 30px auto;
  font-size: 16px;
  padding: 20px 47px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.08em;
  font-family: Montserrat, serif;
  transition: 0.3s;
  width: min-content;

  &:hover {
    background-color: #be996f;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 20px 30px;
  }
`;

const ExternalLink = styled.a`
  color: #58a313;
  margin: 20px 0;
  &:hover {
    text-decoration: underline;
    color: #ac804e;
  }
`;

const List = styled.ul`
  margin: 10px 0 10px 16px;
  columns: ${(props) => props.columns};
  list-style-type: ${(props) => (props.listType ? "none" : "square")};

  list-style-position: outside;
  li:before {
    content: ${(props) => props.listType};
    margin-right: 16px;
    color: #58a313;
  }
  li {
    color: #777;
    margin: 4px 0;
  }
  @media (max-width: 480px) {
    columns: 1;
  }
`;

const ImageWrapper = styled.div`
  margin: 30px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
`;

const GenuineLink = styled(Link)`
  display: block;
  color: #58a313;

  margin: 16px 0;
  &:hover {
    text-decoration: underline;
    color: #ac804e;
  }
`;
