import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function ColumnsLayout(props) {
  const { content } = props;

  return (
    <Container>
      <WidthWrapper>
        {content.map((item, index) => (
          <Column key={index}>
            <Heading>
              <span>{item.heading[0]}</span>
              {item.heading.slice(1)}
            </Heading>
            {item.image && (
              <Image
                src={item.image.src}
                alt={item.image.description || ""}
                title={item.image.title || ""}
                loading="lazy"
                width="370"
                height="240"
                srcSet={item.image.srcSet && item.image.srcSet}
                sizes={item.image.sizes && item.image.sizes}
              />
            )}
            <Subheading>{item.subheading}</Subheading>
            {item.text.map((paragraph) => (
              <Text key={paragraph}>{paragraph}</Text>
            ))}
          </Column>
        ))}
      </WidthWrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f4f6f9;
`;

const WidthWrapper = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: center;
  padding: 30px 30px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  padding: 30px;
`;

const Heading = styled.h2`
  color: #393a3c;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 25px;

  span {
    font-size: 2em;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const Subheading = styled.h3`
  color: green;
  font-weight: 400;
  font-size: 20px;
  margin: 30px 0;
`;

const Text = styled.p`
  color: #777;
  hyphens: auto;
  text-align: justify;
  margin: 4px 0;
`;

ColumnsLayout.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        description: PropTypes.string,
        title: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
      text: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};
